import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#7249b0",
    },
    secondary: {
      main: "#7cfec3",
    },
    text: "white",
  },
  typography: {
    fontFamily: "AkzidenzGroteskPro-BoldEx, sans-serif",
    h1: {
      fontSize: "4.5rem",
      "@media (max-width: 960px)": {
        fontSize: "3rem",
      },
      "@media (max-width: 600px)": {
        fontSize: "1.2rem",
      },
      "@media (max-width: 320px)": {
        fontSize: "1.2rem",
      },
    },
    h2: {
      fontSize: "3rem",
      color: "#7249b1",
      textTransform: "uppercase",
      "@media (max-width: 960px)": {
        fontSize: "2.2rem",
      },
      "@media (max-width: 600px)": {
        fontSize: "1.6rem",
      },
      "@media (max-width: 320px)": {
        fontSize: "1.2rem",
      },
    },
    h3: {
      color: "#7cfec2",
      fontSize: "2.2rem",
      textTransform: "uppercase",
      "@media (max-width: 960px)": {
        fontSize: "1.8rem",
      },
      "@media (max-width: 600px)": {
        fontSize: "1rem",
      },
    },
    h4: {
      fontSize: "1.6rem",
      textTransform: "uppercase",
      color: "#7cfec3",
      "@media (max-width: 960px)": {
        fontSize: "1.4rem",
      },
      "@media (max-width: 600px)": {
        fontSize: "1rem",
      },
    },
    h5: {
      fontSize: "1.2rem",
      textTransform: "uppercase",
      color: "#7cfec2",
      "@media (max-width: 960px)": {
        fontSize: "0.8rem",
      },
      "@media (max-width: 700px)": {
        fontSize: "0.8rem",
      },
    },
    h6: {
      color: "#7cfec2",
      fontSize: "1.25rem",
      textTransform: "uppercase",
      "@media (max-width: 960px)": {
        fontSize: "1rem",
      },
      "@media (max-width: 600px)": {
        fontSize: "0.8rem",
      },
    },
    body1: {
      fontSize: "0.8rem",
      color: "white",
      lineHeight: "1.8",
      fontFamily: "AkzidenzGroteskPro-Ext, sans-serif",
      fontWeight: "bold",
      "@media (min-width: 700px)": {
        fontSize: "1rem",
      },
    },
    body2: {
      color: "#7cfec2",
      fontSize: "0.8rem",
      fontFamily: "AkzidenzGroteskPro-Ext, sans-serif",
      fontWeight: "bold",
      lineHeight: "1.8",
      "@media (min-width: 700px)": {
        fontSize: "1rem",
      },
    },
  },
});
