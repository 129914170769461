import artists from "../static/artists";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ArtistCard from "./ArtistCard";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
    margin: theme.spacing(4, "0px"),
  },
  artistsSection: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "1062px",
    },
  },
  artistsRoot: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  artistsContainer: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    margin: theme.spacing(4, "0px"),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
}));

export default function Artists() {
  const classes = useStyles();

  return (
    <div className={classes.root} id="artists">
      <div className={classes.artistsSection}>
        <Typography variant="h2" color="secondary">
          ARTISTAS
        </Typography>
        <div className={classes.artistsRoot}>
          <div className={classes.artistsContainer}>
            {artists.map((artist, index) => {
              return <ArtistCard artist={artist} key={index} />;
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
