import React from "react";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Link, SvgIcon, Typography } from "@material-ui/core";
import InstagramIcon from "@material-ui/icons/Instagram";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";

const useStyles = makeStyles((theme) => ({
  releaseCard: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "64px",
    "& > *": {
      padding: "0px",
    },
  },
  bannerImg: {
    width: "99.1vw",
    padding: "0px 0px 0px 0px",
    objectFit: "contain",
    [theme.breakpoints.down("lg")]: {
      width: "100vw",
    },
  },
  title: {
    width: "100%",
    alignSelf: "flex-start",
    textTransform: "uppercase",
    padding: "0px",
    margin: theme.spacing(2, "0px"),
    "& > *": {
      margin: theme.spacing(2, "0px"),
    },
  },
  info: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    margin: theme.spacing(2, "0px"),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
    [theme.breakpoints.between(1, 350)]: {
      maxWidth: "280px",
    },
  },
  media: {
    width: "100%",
    maxWidth: "400px",
    display: "flex",
    flexDirection: "column",
    padding: "0px",
  },
  cover: {
    width: "100%",
  },
  bandcampEmbed: {
    width: "100%",
    maxHeight: "42px",
    border: "0",
    margin: theme.spacing(2, "0px"),
  },
  review: {
    width: "100%",
    height: "100%",
    padding: "0px",
    [theme.breakpoints.down("md")]: {
      margin: "16px 0px",
    },
    [theme.breakpoints.up("sm")]: {
      paddingLeft: "24px",
    },
  },
  text: {
    marginBottom: "16px",
  },
  link: {
    "& :hover": {
      textDecoration: "underline",
    },
  },
  mediaLinks: {
    width: "100%",
    display: "flex",
    alignItem: "center",
    padding: "0px",
  },
  mediaIcon: {
    fontSize: "2rem",
  },
}));

export default function ReleaseCard({ release }) {
  const classes = useStyles();

  function SpotifyIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M17.9,10.9C14.7,9 9.35,8.8 6.3,9.75C5.8,9.9 5.3,9.6 5.15,9.15C5,8.65 5.3,8.15 5.75,8C9.3,6.95 15.15,7.15 18.85,9.35C19.3,9.6 19.45,10.2 19.2,10.65C18.95,11 18.35,11.15 17.9,10.9M17.8,13.7C17.55,14.05 17.1,14.2 16.75,13.95C14.05,12.3 9.95,11.8 6.8,12.8C6.4,12.9 5.95,12.7 5.85,12.3C5.75,11.9 5.95,11.45 6.35,11.35C10,10.25 14.5,10.8 17.6,12.7C17.9,12.85 18.05,13.35 17.8,13.7M16.6,16.45C16.4,16.75 16.05,16.85 15.75,16.65C13.4,15.2 10.45,14.9 6.95,15.7C6.6,15.8 6.3,15.55 6.2,15.25C6.1,14.9 6.35,14.6 6.65,14.5C10.45,13.65 13.75,14 16.35,15.6C16.7,15.75 16.75,16.15 16.6,16.45M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
      </SvgIcon>
    );
  }

  function BandcampIcon(props) {
    return (
      <SvgIcon {...props}>
        <path d="M22,6L15.5,18H2L8.5,6H22Z" />
      </SvgIcon>
    );
  }

  const {
    name,
    artist,
    cover,
    banner,
    instagram,
    facebook,
    spotify,
    youtube,
    bandcampLink,
    bandcampSrc,
    review,
  } = release;

  return (
    <div className={classes.releaseCard}>
      <img alt="" src={banner} className={classes.bannerImg} />
      <Container className={classes.title}>
        <Typography color="secondary" variant="h1">
          {artist}{" "}
        </Typography>
        <Typography color="secondary" variant="h3">
          {name}{" "}
        </Typography>
      </Container>

      <Container className={classes.info}>
        <Container className={classes.media}>
          <img alt="" src={cover} className={classes.cover} />
          <iframe
            title={name}
            className={classes.bandcampEmbed}
            src={bandcampSrc}
            seamless
          >
            <a href={bandcampLink}>
              {name} by {artist}
            </a>
          </iframe>
        </Container>

        <Container className={classes.review}>
          <Typography className={classes.text} variant="body1">
            {review}
          </Typography>

          <Typography className={classes.link} variant="h6" color="secondary">
            <Link
              color="inherit"
              href={bandcampLink}
              target="_blank"
              rel="noreferrer"
            >
              BUY DIGITAL ALBUM
            </Link>
          </Typography>

          <Typography className={classes.link} variant="h6" color="secondary">
            <Link
              color="inherit"
              href={bandcampLink}
              target="_blank"
              rel="noreferrer"
            >
              BUY COMPACT DISC
            </Link>
          </Typography>

          <Container className={classes.mediaLinks}>
            <Link href={instagram} target="_blank" rel="noreferrer">
              <IconButton color="secondary" style={{ paddingLeft: "0" }}>
                <InstagramIcon className={classes.mediaIcon} />
              </IconButton>
            </Link>
            <Link href={facebook} target="_blank" rel="noreferrer">
              <IconButton color="secondary">
                <FacebookIcon className={classes.mediaIcon} />
              </IconButton>
            </Link>

            <Link href={spotify} target="_blank" rel="noreferrer">
              <IconButton>
                <SpotifyIcon
                  className={classes.mediaIcon}
                  color="secondary"
                ></SpotifyIcon>
              </IconButton>
            </Link>

            <Link href={bandcampLink} target="_blank" rel="noreferrer">
              <IconButton>
                <BandcampIcon
                  className={classes.mediaIcon}
                  color="secondary"
                ></BandcampIcon>
              </IconButton>
            </Link>

            <Link href={youtube} target="_blank" rel="noreferrer">
              <IconButton color="secondary">
                <YouTubeIcon className={classes.mediaIcon} />
              </IconButton>
            </Link>
          </Container>
        </Container>
      </Container>
    </div>
  );
}
