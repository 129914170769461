import { React, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Link as Scroll } from "react-scroll";
import { Drawer as MUIDrawer, Typography } from "@material-ui/core/";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import CloseSharpIcon from "@material-ui/icons/CloseSharp";
import useWindowSize from "../hooks/useWindowSize";
import MediaLinks from "./MediaLinks";

const useStyles = makeStyles((theme) => ({
  // NAVBAR
  appbar: {
    width: "100vw",
    height: "76px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
    background: "transparent",
    position: "fixed",
    zIndex: "1100",
    [theme.breakpoints.up("sm")]: {
      height: "88px",
    },

    /* Animations */
    transitionTimingFunction: "ease-in",
    transition: "all 0.5s",
  },
  navLinksContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    listStyle: "none",
    padding: "0 16px 0px 8px",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "1200px",
      padding: "0 12px 0px 4px",
    },
  },
  navigation: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    transitionTimingFunction: "ease-in",
    transition: "all 0.5s",
  },
  navigation__active: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    transitionTimingFunction: "ease-in",
    transition: "all 0.5s",
    opacity: "0",
  },
  navLinks: {
    display: "flex",
    alignItems: "center",

    "& li": {
      margin: theme.spacing(1),
      "&:hover": {
        textDecoration: "underline",
      },
      cursor: "pointer",
      [theme.breakpoints.up("md")]: {
        margin: theme.spacing(5),
      },
    },
  },
  logoContainer: {
    width: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0px",
    cursor: "pointer",
    [theme.breakpoints.up("sm")]: {
      width: "50px",
    },
  },
  logo: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain",
    margin: "0px",
    padding: "0px",
  },
  iconContainer: {
    padding: "0",
  },
  menuIcon: {
    margin: "0",
    padding: "0",
    color: "#7cfec2",
    fontSize: "2rem",
    [theme.breakpoints.up("sm")]: {
      fontSize: "3rem",
    },
  },
  //   DRAWER
  drawerRoot: {
    width: "100vw",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#7cfec2",
  },
  drawerContainer: {
    width: "100%",
    padding: "0 16px 0px 8px",

    [theme.breakpoints.up("sm")]: {
      maxWidth: "1200px",
      padding: "0 12px 0px 4px",
    },
  },
  drawerHeader: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  drawer: {
    height: "76px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      height: "88px",
    },
  },
  drawerMenuIcon: {
    margin: "0",
    padding: "0",
    fontSize: "3rem",
  },
  drawerLinksContainer: {
    width: "100%",
    marginLeft: "15px",
  },
  drawerLinks: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    listStyle: "none",
    padding: "0px",
    fontWeight: "bolder",
  },
  drawerItem: {
    height: "90px",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      margin: theme.spacing(1, "0px"),
      fontSize: "1.8rem",
      height: "60px",
    },
    "& > *": {
      cursor: "pointer",
    },
  },
  mediaLinksContainer: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "15px",
  },
  contacts: {
    display: "flex",
    flexDirection: "column",
    margin: "28px 0px 0px 15px",

    "& > *": {
      textTransform: "lowercase",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "0.8rem",
    },
  },
}));

export default function NavigationBar() {
  const classes = useStyles();
  const { windowSize } = useWindowSize();
  const [open, setOpen] = useState(false);
  const [show, handleShow] = useState(false);

  const handleDrawer = () => {
    setOpen(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 90) {
        handleShow(true);
      } else handleShow(false);
    });
    return () => {
      window.removeEventListener("scroll");
    };
  }, []);

  const navLinks = [
    { text: "LANÇAMENTOS", to: "releases" },
    { text: "ARTISTAS", to: "artists" },
    { text: "MANIFESTO", to: "manifesto" },
    { text: "CONTACTOS", to: "footer" },
  ];
  return (
    <>
      <div className={classes.root}>
        <div className={classes.appbar}>
          <Toolbar className={classes.navLinksContainer}>
            <Scroll to="headline" smooth={true}>
              <div className={classes.logoContainer}>
                <img
                  src={process.env.PUBLIC_URL + "/assets/logo_transparent.png"}
                  alt=""
                  className={classes.logo}
                />
              </div>
            </Scroll>
            <div className={classes.navLinks}>
              <div
                className={
                  show ? classes.navigation__active : classes.navigation
                }
              >
                {navLinks.map((item, index) => {
                  const { text, to } = item;

                  return (
                    windowSize > 900 && (
                      <li key={index}>
                        <Typography variant="h5">
                          <Scroll to={to} smooth={true}>
                            {text}
                          </Scroll>
                        </Typography>
                      </li>
                    )
                  );
                })}
              </div>
            </div>
            <IconButton
              aria-label="menu"
              className={classes.iconContainer}
              onClick={handleDrawer}
            >
              <MenuIcon className={classes.menuIcon} />
            </IconButton>
          </Toolbar>
        </div>
      </div>
      <div>
        <MUIDrawer anchor="left" onClose={() => setOpen(false)} open={open}>
          {/* DRAWER */}
          <div className={classes.drawerRoot}>
            <div id="drawerContainer" className={classes.drawerContainer}>
              {/* DRAWER HEADER */}
              <header className={classes.drawerHeader}>
                <div className={classes.drawer}>
                  <Scroll to="" smooth={true}>
                    <IconButton
                      aria-label="menu"
                      onClick={() => setOpen(false)}
                      className={classes.iconContainer}
                    >
                      <div className={classes.logoContainer}>
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/logo_s_primary_transparent.png"
                          }
                          alt=""
                          className={classes.logo}
                        />
                      </div>
                    </IconButton>
                  </Scroll>
                  <IconButton
                    aria-label="close"
                    color="primary"
                    className={classes.iconContainer}
                    onClick={() => setOpen(false)}
                  >
                    <CloseSharpIcon className={classes.drawerMenuIcon} />
                  </IconButton>
                </div>
              </header>

              {/* DRAWER LINKS */}
              <div className={classes.drawerLinksContainer}>
                <ul className={classes.drawerLinks}>
                  {navLinks.map((item, index) => {
                    const { text, to } = item;
                    return (
                      <li key={index} className={classes.drawerItem}>
                        <Typography variant="h2">
                          <Scroll
                            to={to}
                            smooth={true}
                            onClick={() => setOpen(false)}
                          >
                            {text}
                          </Scroll>
                        </Typography>
                      </li>
                    );
                  })}
                </ul>
              </div>

              {/* DRAWER MEDIA */}
              <div className={classes.mediaLinksContainer}>
                <MediaLinks color="primary" />
              </div>
              <div className={classes.contacts}>
                <Typography variant="h5" color="primary">
                  saliva.diva.label@gmail.com
                </Typography>
                <Typography variant="h5" color="primary">
                  newsletter@salivadiva.pt
                </Typography>
              </div>
            </div>
          </div>
        </MUIDrawer>
      </div>
    </>
  );
}
