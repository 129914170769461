const artists = [
  // {
  //   name: "Splitterzelle",
  //   image: process.env.PUBLIC_URL + "/assets/artist_splitterzelle.jpeg",
  //   bandcampUrl:
  //     "https://salivadiva.bandcamp.com/album/drumhard-sessions-vol-1",
  //   instagram: "https://www.instagram.com/",
  //   facebook: "https://www.facebook.com/",
  //   email: "",
  //   youtube: "https://www.youtube.com/@iforgotiwasme/",
  //   spotify: "",
  // },
  {
    name: "Ξvдyд",
    image: process.env.PUBLIC_URL + "/assets/artist_evaya.jpeg",
    bandcampUrl:
      "https://salivadiva.bandcamp.com/album/abaixo-das-ra-zes-deste-jardim",
    instagram: "https://www.instagram.com/evaya___/",
    facebook: "https://www.facebook.com/evayamusic",
    email: "agente@haushaus.pt",
    youtube: "https://www.youtube.com/@iforgotiwasme/",
    spotify:
      "https://open.spotify.com/artist/3QH6q9XnQDc8zXKJubY7FP?si=OrCtcvPmR-GYV-qOgJSUbA",
  },
  {
    name: "Galgo",
    image: process.env.PUBLIC_URL + "/assets/artist_galgo.jpeg",
    bandcampUrl: "https://salivadiva.bandcamp.com/album/vapor",
    instagram: "https://www.instagram.com/galgogalgogalgo/",
    facebook: "https://www.facebook.com/galgogalgogalgo/?locale=pt_PT",
    email: "galgo.cinco@gmail.com",
    youtube: "https://www.youtube.com/@GalgoOficial/videos",
    spotify:
      "https://open.spotify.com/artist/64eOzQ6QML61ZxmirShdNV?si=nA6x8D6YTjOmEe0PgY-KoQ",
  },
  {
    name: "MONCHMONCH",
    image: process.env.PUBLIC_URL + "/assets/artist_monchmonch.jpeg",
    bandcampUrl: "https://salivadiva.bandcamp.com/album/guardilha-espanca-tato",
    instagram: "https://www.instagram.com/monch_monch_/",
    facebook: "",
    email: "",
    youtube: "https://www.youtube.com/@monchmonch6272",
    spotify:
      "https://open.spotify.com/artist/0V1HM3Br0lGSSpOFNm7CJp?si=Zve4adWmSvG_bISZWjIXPQ",
  },
  {
    name: "Luís Contrário",
    image: process.env.PUBLIC_URL + "/assets/artist_luis_contrario.jpeg",
    bandcampUrl:
      "https://salivadiva.bandcamp.com/album/m-sicas-de-dan-a-para-pessoas-tristes",
    instagram: "https://www.instagram.com/luiscontrario",
    facebook: "",
    email: "",
    youtube: "https://www.youtube.com/@LuisContrario",
    spotify:
      "https://open.spotify.com/artist/4cKzyLlVgnIcpvReKCgD97?si=MDOFPJCDQj2uCRl2rVfNOg",
  },
  {
    name: "Marquise",
    image: process.env.PUBLIC_URL + "/assets/artist_marquise.jpg",
    bandcampUrl: "https://salivadiva.bandcamp.com/album/marquise",
    instagram: "https://www.instagram.com/m.ar.qui.se/",
    facebook: "",
    email: "somososmarquise@gmail.com",
    youtube: "https://www.youtube.com/channel/UCrmAbGsZKBAmKPWsYcQ3TDw",
    spotify:
      "https://open.spotify.com/album/2ZlSaVEe3RVq8DszLuNzFX?si=P9cnlV0AS9aENeHwwUv8OQ",
  },
  {
    name: "Nile Valley",
    image: process.env.PUBLIC_URL + "/assets/artist_nile_valley.jpeg",
    bandcampUrl: "https://salivadiva.bandcamp.com/album/floating-lines",
    instagram: "https://www.instagram.com/nilevalleyband/",
    facebook: "https://www.facebook.com/nilevalleyband/",
    email: "nilevalleymusicpt@gmail.com",
    youtube: "https://www.youtube.com/channel/UCF42RdykH5h1w5sTEEJL8EA",
    spotify:
      "https://open.spotify.com/artist/2NzXt0c7Yb8uiasuBQ767v?si=f6oi0Q4-RrG1sOzoZkiNgQ",
  },
  {
    name: "Palankalama",
    image: process.env.PUBLIC_URL + "/assets/artist_palankalama.jpg",
    bandcampUrl: "https://salivadiva.bandcamp.com/album/lama-pela-anca",
    instagram: "https://www.instagram.com/palankalama/",
    facebook: "https://www.facebook.com/palankalama/",
    email: "",
    youtube: "https://www.youtube.com/channel/UCh3Vq9TTXkE7fsfwFxhQmjQ",
    spotify:
      "https://open.spotify.com/artist/2qadak2dXVVLHDpyfAuCVV?si=rLYKuS26SmSEvmTZC0tLCA",
  },
  {
    name: "Melquiades",
    image: process.env.PUBLIC_URL + "/assets/artist_melquiades.jpg",
    bandcampUrl: "https://salivadiva.bandcamp.com/album/fountain-of-shingle",
    instagram: "https://www.instagram.com/melquiadesband/",
    facebook: "https://www.facebook.com/melquiadesband/",
    email: "",
    youtube: "https://www.youtube.com/channel/UCE1E5-dRL69_oWTttksu91A/",
    spotify:
      "https://open.spotify.com/artist/0SWnKKtr87ZnJwKclUwuM7?si=j7oQQauvSn22mJFfBcKEEA",
  },
  {
    name: "Fugly",
    image: process.env.PUBLIC_URL + "/assets/artist_fugly.jpg",
    bandcampUrl: "https://salivadiva.bandcamp.com/album/dandruff",
    instagram: "https://www.instagram.com/fuglyfuglyfugly/",
    facebook: "https://www.facebook.com/fuglyfuglyfugly/",
    email: "",
    youtube: "https://www.youtube.com/channel/UCiA17QHA29eFaO0rg5p82Fg/",
    spotify:
      "https://open.spotify.com/artist/5CrUuS7NQvUeHwPFnzVKcS?si=77cuczFhTqGTAX0vlL-XQA",
  },
  {
    name: "Baleia Baleia Baleia",
    image: process.env.PUBLIC_URL + "/assets/artist_baleia.png",
    bandcampUrl: "https://salivadiva.bandcamp.com/track/egossistema",
    instagram: "https://www.instagram.com/baleia_baleia_baleia/",
    facebook: "https://www.facebook.com/Baleiax3/",
    email: "",
    youtube:
      "https://www.youtube.com/channel/UCCloJP65wVpQ-E8cek8g5KA/featured",
    spotify:
      "https://open.spotify.com/artist/1M66J2yfngcMDmYSLiAUe2?si=VgBQYfnfSpqqRKOBBhit-g",
  },
  {
    name: "Daniel Catarino",
    image: process.env.PUBLIC_URL + "/assets/artist_catarino.png",
    bandcampUrl: "https://salivadiva.bandcamp.com/album/isolamento-volunt-rio",
    instagram: "",
    facebook: "https://www.facebook.com/catarinodaniel",
    email: "",
    youtube: "https://www.youtube.com/c/DanielCatarino",
    spotify:
      "https://open.spotify.com/artist/4viq1TlK4HK08LsQeInUwF?si=5E66ZJJ_TuSoQ0K4-KKIQQ",
  },
  {
    name: "O Manipulador",
    image: process.env.PUBLIC_URL + "/assets/artist_o_manipulador.png",
    bandcampUrl: "https://salivadiva.bandcamp.com/album/doppler",
  },
  {
    name: "Palmiers",
    image: process.env.PUBLIC_URL + "/assets/artist_palmiers.png",
    bandcampUrl: "https://salivadiva.bandcamp.com/album/palmiers",
  },

  {
    name: "The Miami Flu",
    image: process.env.PUBLIC_URL + "/assets/artist_the_miami_flu.png",
    bandcampUrl: "https://salivadiva.bandcamp.com/album/reunion-day",
    instagram: "https://www.instagram.com/themiamiflu/",
    facebook: "https://pt-pt.facebook.com/themiamiflu/",
    email: "info@miamiflu.com",
    youtube: "https://www.youtube.com/channel/UCF6n5p_m-c8nP0CSsayj91Q",
    spotify:
      "https://open.spotify.com/artist/582sJBlIOnWBuEd0wxzkvb?si=bDY8DJNMRECsYWDKtp2BBA",
  },
  {
    name: "Tiago e os Tintos",
    image: process.env.PUBLIC_URL + "/assets/artist_tiago_e_os_tintos.png",
    bandcampUrl: "https://salivadiva.bandcamp.com/album/o-ecoar-duma-sirene",
  },
];

export default artists;
