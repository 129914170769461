import { CssBaseline } from "@material-ui/core";
import Artists from "./components/Artists";
import Footer from "./components/Footer";
import Headline from "./components/Headline";
import Manifesto from "./components/Manifesto";
import NavigationBar from "./components/NavigationBar";
import Releases from "./components/Releases";

function App() {
  return (
    <>
      <CssBaseline />
      <NavigationBar />
      <Headline />
      <Releases />
      <Artists />
      <Manifesto />
      <Footer />
    </>
  );
}

export default App;
