import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    padding: "0px",
    marginTop: "16px",
    marginBottom: "16px",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "1062px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0px 50px",
    },
  },
  title: {
    margin: theme.spacing(2, "0px"),
  },
  textContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    padding: "0px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  text: {
    width: "100%",
    color: "white",
    margin: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      margin: "16px 0px",
      paddingRight: "16px",
    },
  },
  divider: {
    width: "100%",
    border: "2px solid #7cfec2",
  },
}));

export default function Manifesto() {
  const classes = useStyles();

  return (
    <Container className={classes.root} id="manifesto">
      {/* <Typography variant="h2" color="secondary">
        ABOUT
      </Typography> */}
      <Typography variant="h1" color="secondary" className={classes.title}>
        MANIFESTO
      </Typography>
      <Container className={classes.textContainer}>
        <Typography
          variant="body1"
          className={classes.text}
          style={{ marginLeft: "0px" }}
        >
          As editoras independentes surgem quase sempre pelo mesmo motivo: dar
          espaço comum a artistas que caminham isoladamente. A Saliva Diva
          privilegia mais o espírito comunitário e menos as conquistas
          individuais. Acreditamos na função utilitária da produção criativa
          como forma de catarse e união, como estímulo sensorial e intelectual,
          como embalo e despertador. Também acreditamos nela como entretenimento
          de qualidade. Tal como vocês, queremos rir, chorar, pensar, bezerrar e
          dançar. Pretendemos ser um elo que facilite a união entre criador e
          ouvinte. Não trabalhamos apenas para quem já toma a iniciativa de
          explorar o que acontece por detrás do óbvio. Queremos que os nossos
          discos descubram novas casas, que cheguem a um público mais universal
          sem sacrificar a liberdade criativa das obras que editamos.
        </Typography>
        <Typography
          variant="body1"
          className={classes.text}
          style={{ marginRight: "0px" }}
        >
          Queremos um espaço onde nos podemos encontrar, sem termos de ser nicho
          ou produzir escassez. Toda a gente é bem-vinda! No fundo, queremos
          partilhar convosco música que consideramos especial, com capas bonitas
          e um cuidado que só o trabalho em equipa consegue garantir. Também
          queremos reforçar e abrir esse espírito comunitário em eventos como
          debates, sessões de audição, concertos, e claro, festas. Não
          acreditamos em concorrência na arte - aprendemos muito com
          antecessores que agora são colegas na edição independente, e estamos
          abertos a sinergias que relevem a música que é feita nos quartos e
          estúdios do nosso país. É assim a vossa Saliva Diva - muito mais do
          que discos numa estante!
        </Typography>
      </Container>
      <hr className={classes.divider} />
    </Container>
  );
}
