import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Typography } from "@material-ui/core";
import MediaLinks from "./MediaLinks";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "0px",
    marginTop: "16px",
    marginBottom: "16px",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "1062px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0px 50px",
    },
  },
}));

export default function Footer() {
  const classes = useStyles();

  return (
    <Container id="footer" className={classes.root}>
      <Typography variant="h1" color="secondary" gutterBottom>
        CONTACTOS
      </Typography>
      <Typography variant="body1">
        saliva.diva.label@gmail.com
        <br />
        newsletter@salivadiva.pt
      </Typography>
      <MediaLinks color="secondary" />
    </Container>
  );
}
