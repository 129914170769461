import releases from "../static/releases";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReleaseCard from "./ReleaseCard";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  releasesRoot: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    marginTop: "90px",
    [theme.breakpoints.up("sm")]: {
      maxWidth: "1062px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "0px 50px",
    },
  },
  releasesContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "40px",
  },
}));

export default function Releases() {
  const classes = useStyles();

  return (
    <div className={classes.root} id="releases">
      <div className={classes.releasesRoot}>
        <Typography variant="h2" color="secondary">
          LANÇAMENTOS
        </Typography>
        <div className={classes.releasesContainer}>
          {releases.map((release, index) => {
            return <ReleaseCard release={release} key={index} />;
          })}
        </div>
      </div>
    </div>
  );
}
