import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  container: {
    lineWeight: "0",
    width: "400px",
    height: "400px",
    alignItems: "center",
    margin: theme.spacing(4, "0px"),
    position: "relative",
    background: "transparent",
    [theme.breakpoints.up("md")]: {
      background: "#7249b0",
    },
    "&:hover .imageHover": {
      opacity: "0.7",
    },
    "&:hover .showInfo": {
      opacity: "1",
    },
    [theme.breakpoints.down("md")]: {
      margin: theme.spacing(2, "0px"),
      maxWidth: "260px",
      opacity: "1",
      "& > .imageHover": {
        opacity: "0.7",
      },
      "& > .showInfo": {
        opacity: "1",
      },
      height: "260px",
      background: "#7249b0",
    },
    [theme.breakpoints.between(1, 350)]: {
      maxWidth: "200",
      maxHeight: "200",
    },
  },

  image: {
    width: "100%",
    height: "inherit",
    objectFit: "contain",
    transitionTimingFunction: "ease-in",
    transition: "all 0.5s",
    "&:hover": {
      transitionTimingFunction: "ease-out",
      transition: "all 0.5s",
    },
    [theme.breakpoints.down("md")]: {
      opacity: "1",
    },
  },
  artistInfo: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    justifyContent: "center",
    alignItems: "center",
    top: "0%",
    left: "0%",
    transitionTimingFunction: "ease-in",
    transition: "0.5s ease",
    opacity: "0",
    textAlign: "center",
    "& > *": {
      cursor: "pointer",
      textDecoration: "none",
    },
  },
  name: {
    margin: theme.spacing(2, "0px"),
    color: "#7cfec2",
  },
}));

export default function Artist({ artist }) {
  const classes = useStyles();
  const { name, image, bandcampUrl } = artist;

  return (
    <div className={classes.container}>
      <img alt="" src={image} className={`${classes.image} imageHover`} />
      <div className={`${classes.artistInfo} showInfo`}>
        <a href={bandcampUrl} target="_blank" rel="noreferrer">
          <Typography className={classes.name} variant="h2">
            {name}
          </Typography>
        </a>
      </div>
    </div>
  );
}
