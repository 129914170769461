import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "@material-ui/core/Fade";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  headlineRoot: {
    width: "100%",
    height: "90vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "30px",
  },
  background: {
    top: "0px",
    objectFit: "cover",
    width: "100%",
    height: "100%",
    position: "fixed",
    zIndex: "-999",
  },
  logoContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logo: {
    maxWidth: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "65%",
    },
  },
  subscribe: {
    position: "absolute",
    bottom: "0",
    marginBottom: "30px",
    padding: "60px 0px 50px 0px",
  },
}));

export default function Headline() {
  const classes = useStyles();

  return (
    <div id="headline" className={classes.headlineRoot}>
      <img
        className={classes.background}
        src={process.env.PUBLIC_URL + "/assets/fundo_1920_1080.png"}
        alt="background"
      ></img>
      <Fade in={true}>
        <div className={classes.logoContainer}>
          <img
            className={classes.logo}
            alt=""
            src={
              process.env.PUBLIC_URL +
              "/assets/Logos_Saliva Diva_sem fundo2_secondary2.png"
            }
          />
        </div>
      </Fade>
      <div className={classes.subscribe}>
        <a
          href="http://eepurl.com/hndptv"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Typography className={classes.name} variant="h4" color="secondary">
            Subscrever Newsletter
          </Typography>
        </a>
      </div>
    </div>
  );
}
